<template>
  <section>
    <el-row :gutter="10">
      <el-col :xs="20" :sm="3" class="mr-2">
        <el-button type="primary" icon="el-icon-arrow-left" class="mr-1 mb-3" plain @click="$router.back()">Back
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="20" :md="18">
        <div class="pay_password" v-loading.fullscreen.lock="loading">
          <!-- 支付密码 -->
          <b-modal id="payPassword" centered :title="$t('PayMoney.661@payment_pasword')" hide-footer>
            <div class="wrap">
              <input class="pwd" @input="onload" v-model="pass1" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass2" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass3" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass4" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass5" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass6" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
            </div>
            <button type="button" class="btn btn-primary mt-2 float-right" @click="verifyPaymentPassword">
              {{ $t("PayMoney.c5a@submit") }}
            </button>
          </b-modal>

          <div v-if="!paymentConfirm">
            <iq-card>
              <template v-slot:body>
                <h4 class="form-text">
                  {{ $t("PayMoney.bf4@payment_method") }}
                </h4>
                <b-form-group class="mt-3" v-if="$route.query.is_subscribe==2">
                  <b-form-radio class='activeType' value="1" v-model="defaultVal">
                    {{selectedActive==2?'PayPal':'Credit card payment'}}
                  </b-form-radio>
                </b-form-group>
                <section v-else>
                  <b-form-group class="mt-3" v-if="$route.query.is_subscribe">
                    <b-form-radio :class="selectedActive==2?'activeType':''" v-model="selectedActive"
                      name="Credit card payment" value="2" v-if="!$route.query.is_subscribe">
                      PayPal</b-form-radio>
                    <b-form-radio :class="selectedActive==5?'activeType':''" name="Stripe Payment" value="5"
                      v-model="selectedActive">
                      Credit card payment</b-form-radio>
                  </b-form-group>
                  <b-form-group class="mt-3" v-else>
                    <!-- <div class="d-flex pr-5">
                  <b-form-radio
                    v-model="selectedActive"
                    name="Top-up balance"
                    value="1"
                    >{{ $t("PayMoney.a01@top_up_balance") }}
                  </b-form-radio>
                  <span>${{ currentBalance }}</span>
                </div> -->
                    <!-- <b-form-radio
                  v-model="selectedActive"
                  name="Credit Card"
                  value="2"
                  >{{ $t("PayMoney.ee0@credit_card") }}</b-form-radio
                > -->
                    <b-form-radio :class="selectedActive==2?'activeType':''" v-model="selectedActive"
                      name="Credit card payment" value="2" v-if="goods_info.currency != 1&&pay_type.paypal">
                      PayPal</b-form-radio>
                    <b-form-radio :class="selectedActive==5?'activeType':''" name="Stripe Payment" value="5"
                      v-model="selectedActive" v-if="goods_info.currency == 1||pay_type.stripe">
                      Credit card payment</b-form-radio>
                    <b-form-radio :class="selectedActive==3?'activeType':''" v-model="selectedActive"
                      name="Bank/Wire Transfer" value="3"
                      v-if="single == 1 && goods_info.currency != 1&&pay_type.single">{{
                      $t("PayMoney.4a3@single_offline_payme") }}</b-form-radio>
                    <b-form-radio :class="selectedActive==4?'activeType':''" name="Bank/Wire Transfer" value="4"
                      v-if="batch == 1 && goods_info.currency != 1&&pay_type.batch" v-model="selectedActive">{{
                      $t("PayMoney.37e@batch_offline_paymen") }}</b-form-radio>
                  </b-form-group>
                </section>

                <!-- 余额支付支付密码 -->
              </template>
            </iq-card>

            <!-- 订阅消息-->
            <iq-card class="goods_info_style" v-if="$route.query.is_subscribe">
              <template v-slot:body>
                <div class="goods_item mb-1">
                  <span class="left">Service: </span>
                  <span class="right">Donation Subscription</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Description: </span>
                  <span class="right">Donation Recipients-JCl Foundation</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Payment Type: </span>
                  <span class="right">Subscription</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Interval: </span>
                  <span class="right">Monthly</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Start Date: </span>
                  <span class="right">{{subscribe_info.start_date|formatDateSub}}</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Has End Date: </span>
                  <span class="right">{{subscribe_info.is_end_date?'Yes':'No'}}</span>
                </div>
                <div class="goods_item mb-1" v-if="subscribe_info.is_end_date">
                  <span class="left">End Date: </span>
                  <span class="right">{{subscribe_info.end_date|formatDateSub}}</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Donation Amount: </span>
                  <span class="right">
                    {{ subscribe_info.currency != 1 ? "$" : "€"
                    }} {{ subscribe_info.donation_amount }}
                  </span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Commission: </span>
                  <span class="right">
                    {{ subscribe_info.currency != 1 ? "$" : "€"
                    }} {{( Number(subscribe_info.amount)-Number(subscribe_info.donation_amount)).toFixed(2) }}
                  </span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Total Amount: </span>
                  <span class="right">
                    {{ subscribe_info.currency != 1 ? "$" : "€"}}
                    <span>{{subscribe_info.amount}}</span>
                  </span>
                </div>
              </template>
            </iq-card>

            <!-- goods info  -->
            <iq-card class="goods_info_style" v-else>
              <template v-slot:body>
                <div class="goods_item mb-1">
                  <span class="left">Order Number: </span>
                  <span class="right">{{ goods_info.order_number }}</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Service: </span>
                  <span class="right">{{ goods_info.service }}</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Description: </span>
                  <span class="right">{{ goods_info.description }}</span>
                </div>
                <div class="goods_item mb-1" v-if="$route.query.model_type==8">
                  <span class="left">Donation Amount: </span>
                  <span class="right">
                    {{ goods_info.currency != 1 ? "$" : "€"
                    }} {{ goods_info.donation_amount }}
                  </span>
                </div>
                <div class="goods_item mb-1" v-if="$route.query.model_type==8">
                  <span class="left">Commission: </span>
                  <span class="right">
                    {{ goods_info.currency != 1 ? "$" : "€"
                    }} {{ goods_info.fee }}
                  </span>
                </div>
                <div class="goods_item mb-1" v-if="['2', '4'].includes($route.query.model_type)">
                  <span class="left">Unit Price: </span>
                  <span class="right">{{ goods_info.currency != 1 ? "$" : "€"
                    }}{{ goods_info.unit_price }}
                  </span>
                </div>
                <div v-if="['2', '4'].includes($route.query.model_type)" class="goods_item mb-1">
                  <span class="left">Quantity: </span>
                  <span class="right">{{ goods_info.number }}</span>
                </div>
                <div class="goods_item mb-1">
                  <span class="left">Total Amount: </span>
                  <span class="right">
                    {{ goods_info.currency != 1 ? "$" : "€"}}
                    <!--  v-if="['2', '4','5', '6', '8'].includes($route.query.model_type)" -->
                    <span>{{goods_info.total_price}}</span>
                    <!-- <span v-else>{{ goods_info.price }}</span>  -->
                  </span>
                </div>
              </template>
            </iq-card>

            <!-- 信用卡支付 -->
            <iq-card v-show="false">
              <template v-slot:body>
                <h4 class="form-text">
                  {{ $t("PayMoney.fa8@payment_details") }}
                </h4>
                <b-form class="mt-3">
                  <b-form-group>
                    <span class="appendAsterisk">{{
                      $t("PayMoney.670@card_number")
                      }}</span>
                    <b-row>
                      <b-col :span="12">
                        <b-form-input v-model="params.card_number" @keyup="
                            params.card_number = params.card_number.replace(
                              /[^\d]/g,
                              ''
                            )
                          " type="text" :placeholder="$t('PayMoney.670@card_number')" required></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group>
                    <span class="appendAsterisk">{{ $t("PayMoney.a31@cvnumber") }}
                      <span style="color: #ffba68">
                        <svg width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-question-circle"
                          fill="currentColor" xmlns="http://www.w3.org/2000/svg" @mouseenter="tipBlock"
                          @mouseleave="tipNone">
                          <path fill-rule="evenodd"
                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path
                            d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                        </svg>
                      </span>
                    </span>
                    <div class="cvnTips" v-if="isTip">
                      Location Of The CV Number:Three of the four major credit
                      card companies — Visa, Mastercard and Discover — use a
                      three-digit CV number. It is printed on the back of the
                      card, to the right of the 16-digit account number in the
                      signature strip.
                    </div>
                    <b-row>
                      <b-col :span="12">
                        <b-form-input v-model="params.cvNumber" type="password"
                          :placeholder="$t('PayMoney.a31@cvnumber')" required></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-row>
                    <b-col :span="12">
                      <b-form-group>
                        <span class="appendAsterisk">{{
                          $t("PayMoney.02a@card_type")
                          }}</span>
                        <select class="form-control p-0" v-model="params.card_type">
                          <option value="" disabled selectedActive style="display: none">
                            {{ $t("PayMoney.02a@card_type") }}
                          </option>
                          <option v-for="item in cardType" :value="item.id" :key="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <span class="appendAsterisk">{{
                      $t("PayMoney.fca@name_on_the_card")
                      }}</span>
                    <b-row>
                      <b-col :span="6">
                        <b-form-input v-model="params.first_name" type="text"
                          :placeholder="$t('PayMoney.1d0@frist_name')" required></b-form-input>
                      </b-col>
                      <b-col :span="6">
                        <b-form-input v-model="params.last_name" type="text" :placeholder="$t('PayMoney.220@last_name')"
                          required></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-row>
                    <b-col :span="6">
                      <b-form-group>
                        <span class="appendAsterisk">{{
                          $t("PayMoney.d27@year")
                          }}</span>
                        <select class="form-control p-0" v-model="params.year">
                          <option value="" disabled selectedActive style="display: none">
                            {{ $t("PayMoney.f2c@select_year") }}
                          </option>
                          <option v-for="year in yearList" :value="year" :key="year">
                            {{ year }}
                          </option>
                        </select>
                      </b-form-group>
                    </b-col>
                    <b-col :span="6">
                      <b-form-group>
                        <span class="appendAsterisk">{{
                          $t("PayMoney.162@month")
                          }}</span>
                        <select class="form-control p-0" v-model="params.month">
                          <option value="" disabled selectedActive style="display: none">
                            {{ $t("PayMoney.9d7@select_month") }}
                          </option>
                          <option v-for="month in monthList" :value="month" :key="month">
                            {{ month }}
                          </option>
                        </select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <span class="appendAsterisk">{{
                      $t("PayMoney.30c@email")
                      }}</span>
                    <b-row>
                      <b-col :span="12">
                        <b-form-input v-model="params.email" type="text" :placeholder="$t('PayMoney.30c@email')"
                          required>
                        </b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-row>
                    <b-col :span="12">
                      <b-form-group>
                        <span class="appendAsterisk">{{
                          $t("PayMoney.ddf@country")
                          }}</span>
                        <select class="form-control p-0" v-model="params.country">
                          <option value="" disabled selectedActive style="display: none">
                            {{ $t("PayMoney.ddf@country") }}
                          </option>
                          <option v-for="item in countryList" :value="item.id" :key="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <span class="appendAsterisk">{{
                      $t("PayMoney.16d@state")
                      }}</span>
                    <b-row>
                      <b-col :span="12">
                        <b-form-input v-model="params.state" type="text" :placeholder="$t('PayMoney.16d@state')"
                          required>
                        </b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group>
                    <span class="appendAsterisk">{{
                      $t("PayMoney.2b4@city")
                      }}</span>
                    <b-row>
                      <b-col :span="12">
                        <b-form-input v-model="params.city" type="text" :placeholder="$t('PayMoney.2b4@city')" required>
                        </b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group>
                    <span class="appendAsterisk">{{
                      $t("PayMoney.ce3@street")
                      }}</span>
                    <b-row>
                      <b-col :span="12">
                        <b-form-input v-model="params.street" type="text" :placeholder="$t('PayMoney.ce3@street')"
                          required>
                        </b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group>
                    <span class="appendAsterisk">{{
                      $t("PayMoney.a98@zip_code_or_postal_c")
                      }}</span>
                    <b-row>
                      <b-col :span="12">
                        <b-form-input v-model="params.postalCode" type="text"
                          :placeholder="$t('PayMoney.a98@zip_code_or_postal_c')" required></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>

            <!-- 订阅支付 -->
            <iq-card v-if="$route.query.is_subscribe">
              <div v-show="['1', '2', '3', '4', '5'].includes(selectedActive)">
                <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                  <input type="checkbox" class="custom-control-input" id="privacyPolicy" v-model="agree" />
                  <label class="custom-control-label" for="privacyPolicy">{{ $t("PayMoney.28b@i_accept") }}
                    <a href="javascript:;" @click="$router.push('/PrivacyAndPolicy?id=3')">{{
                      $t("PayMoney.310@payment_policy") }}</a>
                  </label>
                </div>
                <div class="d-flex justify-content-between align-items-center p-3">
                  <p class="m-0 p-0 totalPri" style="color: #333">
                    <span style="font-weight: 500">Payment Price:</span>
                    <span class="font-weight-bold" style=" font-family: 'poppins'">
                      $ {{ subscribe_info.amount }}
                    </span>
                  </p>
                  <b-button variant="primary" :class="subLoading?'loading_btn':''" class="payBtn" :disabled="!agree"
                    @click="toSubscribePay" v-loading="subLoading">Subscribe</b-button>
                </div>
              </div>
            </iq-card>

            <!-- 点击支付 -->
            <iq-card v-else>
              <div v-show="['1', '2', '3', '4', '5'].includes(selectedActive)">
                <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                  <input type="checkbox" class="custom-control-input" id="privacyPolicy" v-model="agree" />
                  <label class="custom-control-label" for="privacyPolicy">{{ $t("PayMoney.28b@i_accept") }}
                    <a href="javascript:;" @click="$router.push('/PrivacyAndPolicy?id=3')">{{
                      $t("PayMoney.310@payment_policy") }}</a>
                  </label>
                </div>
                <div class="d-flex justify-content-between align-items-center p-3">
                  <p class="m-0 p-0 totalPri" style="color: #333">
                    <span style="font-weight: 500">Payment Price:</span>
                    <span class="font-weight-bold" style=" font-family: 'poppins'">
                      {{ goods_info.currency != 1 ? "$" : "€"
                      }}{{ paymentAmount }}
                    </span>
                    <!-- <span class="fontUnit" style="font-weight: 500; ">{{
                                  goods_info.currency != 1 ? "USD" : "EUR"
                                }}</span> -->
                  </p>
                  <!-- <b-button variant="primary" :disabled="!agree" @click="payConfirm">{{ $t("PayMoney.1b9@next") }}</b-button> -->
                  <b-button variant="primary" class="payBtn" :disabled="!agree" @click="payConfirm">Pay Now</b-button>
                </div>
              </div>
            </iq-card>
          </div>

          <div v-else>
            <!-- 确认订单-------已不需要 -->
            <iq-card v-if="showpaid == 3">
              <div v-if="
                  (selectedActive == '3' || selectedActive == '4') &&
                  showpaid == 0
                " class="p-3">
                <h3>Confirm Order</h3>
                <div class="payment">
                  <p>
                    {{ $t("PayMoney.fdb@amount") }}:
                    <span class="font-weight-bold" style="
                        font-size: 18px;
                        color: #333;
                        font-family: 'poppins';
                      ">${{ paymentAmount }}</span>
                    USD
                  </p>
                  <p>
                    {{ $t("PayMoney.b5b@payment") }}:
                    <span v-if="selectedActive == 3 || common_type == 5">{{
                      $t("PayMoney.060@single_offline_payme")
                      }}</span>
                    <span v-if="selectedActive == 4 || common_type == 5">{{
                      $t("PayMoney.c6a@batch_offline_paymen")
                      }}</span>
                  </p>
                </div>
                <div class="btns">
                  <button class="btn1" @click="payBack">
                    {{ $t("PayMoney.b92@back") }}
                  </button>
                  <button class="btn2" @click="showpaid = 1">
                    {{ $t("PayMoney.e46@pay_now") }}
                  </button>
                </div>
              </div>
              <div class="p-3" v-else>
                <h3>{{ $t("PayMoney.d85@confirm_order") }}</h3>
                <div class="payment">
                  <p>
                    {{ $t("PayMoney.7ba@amount") }}:
                    <span class="font-weight-bold" style="
                        font-size: 18px;
                        color: #333;
                        font-family: 'poppins';
                      ">${{ paymentAmount }}</span>
                    USD
                  </p>
                  <p>
                    Payment:
                    <span v-if="selectedActive == 1">{{
                      $t("PayMoney.a01@top_up_balance")
                      }}</span>
                    <!-- <span v-else-if="selectedActive == 2">{{
                  $t("PayMoney.ee0@credit_card")
                  }}</span> -->
                    <span v-else-if="selectedActive == 2">PayPal</span>
                    <span v-if="selectedActive == 5">Credit card payment</span>
                  </p>
                </div>
                <!-- 信用卡支付确认 -->
                <div class="d-flex flex-column" v-if="false">
                  <div class="num my-3 p-3" style="margin: 0">
                    <h5 class="mb-2">
                      {{ $t("PayMoney.659@bank_card_informatio") }}
                    </h5>
                    <p class="info">
                      {{ $t("PayMoney.8ca@card_number") }} :
                      <span style="color: #333">{{ params.card_number }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.dde@name_on_the_card") }} :
                      <span style="color: #333">{{ params.first_name }} {{ params.last_name }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.ddf@country") }} :
                      <span style="color: #333">{{ params.country }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.2b4@city") }} :
                      <span style="color: #333">{{ params.city }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.ce3@street") }} :
                      <span style="color: #333">{{ params.street }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.4ab@postal_code") }} :
                      <span style="color: #333">{{ params.postalCode }}</span>
                    </p>
                  </div>
                </div>

                <div class="btns">
                  <button v-show="showBtn == '1'" class="btn1" @click="payBack">
                    {{ $t("PayMoney.b92@back") }}
                  </button>
                  <button v-show="showBtn == '2'" class="btn1" @click="goBack()">
                    {{ $t("PayMoney.16d@cancelorder") }}
                  </button>
                  <button class="btn2" @click="handleClickPayNow">
                    {{ $t("PayMoney.e46@pay_now") }}
                  </button>
                </div>
                <!-- 支付回调 -->
                <!-- <iframe name="ddc-iframe" height="0" width="0"></iframe>
            <form
              id="ddc-form"
              target="ddc-iframe"
              method="POST"
              :action="actionCode"
            >
              <input
                type="hidden"
                ref="input"
                name="JWT"
                :value="accessToken"
              />
            </form>
            <iframe
              name="step-up-iframe"
              :height="token475 ? 400 : 0"
              :width="token475 ? 400 : 0"
            ></iframe>
            <form
              id="step-up-form"
              target="step-up-iframe"
              method="POST"
              :action="url475"
            >
              <input type="hidden" ref="int" name="JWT" :value="token475" />
              <input
                type="hidden"
                name="MD"
                value="optionally_include_custom_data_that_will_be_returned_as_is"
              />
            </form> -->
              </div>
            </iq-card>

            <!-- 线下支付确认  -->
            <iq-card v-else-if="showpaid == 1">
              <template>
                <!-- 线下支付确认银行卡信息 -->
                <div v-if="selectedActive == 3" class="d-flex flex-column">
                  <div class="text-center p-5">
                    <img class="w-30" src="@/assets/images/paid.png" alt="" />
                  </div>
                  <div class="tips px-3" v-if="this.offlineShow == false">
                    {{ $t("PayMoney.b09@you_have_chosen_to_p") }}
                  </div>
                  <div class="tips px-3" v-else>
                    {{ $t("PayMoney.b97@uploaded,_awaiting_r") }}
                  </div>
                  <div class="num my-3 p-3" v-if="common_type != 5">
                    <h5 class="mb-2">
                      {{ $t("PayMoney.a19@bank_card_informatio") }}
                    </h5>
                    <p class="info">
                      {{ $t("PayMoney.5a7@bank_account_name") }} :
                      <span style="color: #333">{{ bank_info.title }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.338@account_no_iban") }} :
                      <span style="color: #333">{{ bank_info.account }}</span>
                    </p>

                    <p class="info">
                      {{ $t("PayMoney.237@bank_name") }} :
                      <span style="color: #333">{{ bank_info.bank_name }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.ddf@country") }} :
                      <span style="color: #333">{{ bank_info.nation }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.97d@swift_clearing_cod") }} :
                      <span style="color: #333">{{
                        bank_info.bank_clearing_code
                        }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.fb8@address") }} :
                      <span style="color: #333">{{ bank_info.address }}</span>
                    </p>
                    <p class="info" v-if="this.offlineShow == false">
                      {{ $t("PayMoney.53e@online_pay") }}:
                      <a :href="bank_info.payment_link" target="_blank">{{
                        bank_info.payment_link
                        }}</a>
                    </p>
                  </div>
                  <div class="text-center p-3" v-if="this.offlineShow == false">
                    <b-button variant="primary" @click="showpaid = 2">{{
                      $t("PayMoney.b0d@i_have_finished_the_")
                      }}</b-button>
                  </div>
                </div>
                <div v-else-if="selectedActive == 4" class="d-flex flex-column">
                  <div class="text-center p-5">
                    <img class="w-30" src="@/assets/images/paid.png" alt="" />
                  </div>
                  <div class="tips px-3" v-if="this.offlineShow == false">
                    {{ $t("PayMoney.b09@you_have_chosen_to_p") }}
                  </div>
                  <div class="tips px-3" v-else>
                    {{ $t("PayMoney.b97@uploaded,_awaiting_r") }}
                  </div>
                  <div class="num my-3 p-3">
                    <h5 class="mb-2">
                      {{ $t("PayMoney.a19@bank_card_informatio") }}
                    </h5>
                    <p class="info">
                      {{ $t("PayMoney.5a7@bank_account_name") }} :
                      <span style="color: #333">{{ bankInfo.title }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.338@account_no_iban") }} :
                      <span style="color: #333">{{ bankInfo.account }}</span>
                    </p>

                    <p class="info">
                      {{ $t("PayMoney.237@bank_name") }}:
                      <span style="color: #333">{{ bankInfo.bank_name }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.ddf@country") }} :
                      <span style="color: #333">{{ bankInfo.nation }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.97d@swift_clearing_cod") }} :
                      <span style="color: #333">{{
                        bankInfo.bank_clearing_code
                        }}</span>
                    </p>
                    <p class="info">
                      {{ $t("PayMoney.fb8@address") }} :
                      <span style="color: #333">{{ bankInfo.address }}</span>
                    </p>
                    <p class="info" v-if="this.offlineShow == false">
                      {{ $t("PayMoney.53e@online_pay") }}:
                      <a :href="bankInfo.payment_link" target="_blank">{{
                        bankInfo.payment_link
                        }}</a>
                    </p>
                  </div>
                  <div class="text-center p-3" v-if="this.offlineShow == false">
                    <b-button variant="primary" @click="showpaid = 2">{{
                      $t("PayMoney.b0d@i_have_finished_the_")
                      }}</b-button>
                  </div>
                </div>
              </template>
            </iq-card>
            <!-- 线下支付,上传支付凭证 -->
            <iq-card v-else-if="showpaid == 2">
              <template v-slot:body>
                <h4 class="form-text">
                  {{ $t("PayMoney.fa8@payment_details") }}
                </h4>
                <b-form class="mt-3">
                  <b-form-group label="Payment voucher / bank serial number">
                    <b-form-input v-model="params.transaction_id" type="text"
                      :placeholder="$t('PayMoney.bdb@please_enter_the_ord')" required></b-form-input>
                  </b-form-group>
                  <b-form-group label="Upload Proof">
                    <el-upload class="avatar-uploader" v-loading="loading" action="no" :show-file-list="false"
                      :http-request="uploadVoucher">
                      <img v-if="
                          params.offline_certificate &&
                          fileType != 'application/pdf'
                        " :src="params.offline_certificate" alt="" />
                      <img v-else-if="
                          params.offline_certificate &&
                          fileType == 'application/pdf'
                        " src="../../assets/images/pdf.png" alt="" />
                      <img v-else src="../../assets/images/settings/camera.png" alt="" />
                    </el-upload>
                  </b-form-group>
                </b-form>
                <div class="d-flex justify-content-end">
                  <b-button variant="primary" @click="payMoney()">{{
                    $t("PayMoney.c5a@submit")
                    }}</b-button>
                </div>
              </template>
            </iq-card>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="dialogVisible">
      <StripePay />
    </el-dialog>

    <el-dialog title="Tips" :visible.sync="dialogTip" width="420">
      <span>
        <svg t="1733823348397" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2835" id="mx_n_1733823348397" width="22" height="22">
          <path
            d="M512 992C246.912 992 32 777.088 32 512 32 246.912 246.912 32 512 32c265.088 0 480 214.912 480 480 0 265.088-214.912 480-480 480zM480 256v352a32 32 0 0 0 64 0V256a32 32 0 0 0-64 0z m-16 528a48 48 0 1 0 96 0 48 48 0 0 0-96 0z"
            p-id="2836" fill="#e6a23c"></path>
        </svg>
        Has the payment been confirmed?
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTip = false">Cancel</el-button>
        <el-button type="primary" @click="popupCheckPay">Check</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { year, month } from "../../Utils/getYearMonthDay";
  import StripePay from "./StripePay";
  export default {
    components: { StripePay },
    /**
     *  1  => 活动
     *  2  => 项目
     *  3  => 课程
     *  4  => 奖项
     *  5  => 会员
     */
    props: ["type", "id", "goToId"],
    data() {
      return {
        showBtn: 1,
        loading: false,
        cardType: [
          { id: "001", name: "Visa" },
          { id: "002", name: "MasterCard" },
        ],
        countryList: [
          { name: "Afghanistan", id: "AF" },
          { name: "Albania", id: "AL" },
          { name: "Algeria", id: "DZ" },
          { name: "American Samoa", id: "AS" },
          { name: "Andorra", id: "AD" },
          { name: "Angola", id: "AO" },
          { name: "Anguilla", id: "AI" },
          { name: "Antarctica", id: "AQ" },
          { name: "Antigua And Barbuda", id: "AG" },
          { name: "Argentina", id: "AR" },
          { name: "Armenia", id: "AM" },
          { name: "Aruba", id: "AW" },
          { name: "Australia", id: "AU" },
          { name: "Austria", id: "AT" },
          { name: "Azerbaijan", id: "AZ" },
          { name: "Bahamas", id: "BS" },
          { name: "Bahrain", id: "BH" },
          { name: "Bangladesh", id: "BD" },
          { name: "Barbados", id: "BB" },
          { name: "Belarus", id: "BY" },
          { name: "Belgium", id: "BE" },
          { name: "Belize", id: "BZ" },
          { name: "Benin", id: "BJ" },
          { name: "Bermuda", id: "BM" },
          { name: "Bhutan", id: "BT" },
          { name: "Bolivia", id: "BO" },
          { name: "Bosnia And Herzegowina", id: "BA" },
          { name: "Botswana", id: "BW" },
          { name: "Bouvet Island", id: "BV" },
          { name: "Brazil", id: "BR" },
          { name: "British Indian Ocean Territory", id: "IO" },
          { name: "Brunei Darussalam", id: "BN" },
          { name: "Bulgaria", id: "BG" },
          { name: "Burkina Faso", id: "BF" },
          { name: "Burundi", id: "BI" },
          { name: "Cambodia", id: "KH" },
          { name: "Cameroon", id: "CM" },
          { name: "Canada", id: "CA" },
          { name: "Cape Verde", id: "CV" },
          { name: "Cayman Islands", id: "KY" },
          { name: "Central African Republic", id: "CF" },
          { name: "Chad", id: "TD" },
          { name: "Chile", id: "CL" },
          { name: "China", id: "CN" },
          { name: "Christmas Island", id: "CX" },
          { name: "Cocos (Keeling) Islands", id: "CC" },
          { name: "Colombia", id: "CO" },
          { name: "Comoros", id: "KM" },
          { name: "Congo", id: "CG" },
          { name: "Congo, The Democratic Republic Of The", id: "CD" },
          { name: "Cook Islands", id: "CK" },
          { name: "Costa Rica", id: "CR" },
          { name: "Cote D'ivoire", id: "CI" },
          { name: "Croatia (Local Name: Hrvatska)", id: "HR" },
          { name: "Cuba", id: "CU" },
          { name: "Cyprus", id: "CY" },
          { name: "Czech Republic", id: "CZ" },
          { name: "Denmark", id: "DK" },
          { name: "Djibouti", id: "DJ" },
          { name: "Dominica", id: "DM" },
          { name: "Dominican Republic", id: "DO" },
          { name: "East Timor", id: "TP" },
          { name: "Ecuador", id: "EC" },
          { name: "Egypt", id: "EG" },
          { name: "El Salvador", id: "SV" },
          { name: "Equatorial Guinea", id: "GQ" },
          { name: "Eritrea", id: "ER" },
          { name: "Estonia", id: "EE" },
          { name: "Ethiopia", id: "ET" },
          { name: "Falkland Islands (Malvinas)", id: "FK" },
          { name: "Faroe Islands", id: "FO" },
          { name: "Fiji", id: "FJ" },
          { name: "Finland", id: "FI" },
          { name: "France", id: "FR" },
          { name: "France, Metropolitan", id: "FX" },
          { name: "French Guiana", id: "GF" },
          { name: "French Polynesia", id: "PF" },
          { name: "French Southern Territories", id: "TF" },
          { name: "Gabon", id: "GA" },
          { name: "Gambia", id: "GM" },
          { name: "Georgia", id: "GE" },
          { name: "Germany", id: "DE" },
          { name: "Ghana", id: "GH" },
          { name: "Gibraltar", id: "GI" },
          { name: "Greece", id: "GR" },
          { name: "Greenland", id: "GL" },
          { name: "Grenada", id: "GD" },
          { name: "Guadeloupe", id: "GP" },
          { name: "Guam", id: "GU" },
          { name: "Guatemala", id: "GT" },
          { name: "Guinea", id: "GN" },
          { name: "Guinea-bissau", id: "GW" },
          { name: "Guyana", id: "GY" },
          { name: "Haiti", id: "HT" },
          { name: "Heard And Mc Donald Islands", id: "HM" },
          { name: "Holy See (Vatican City State)", id: "VA" },
          { name: "Honduras", id: "HN" },
          { name: "Hong Kong", id: "HK" },
          { name: "Hungary", id: "HU" },
          { name: "Iceland", id: "IS" },
          { name: "India", id: "IN" },
          { name: "Indonesia", id: "ID" },
          { name: "Iran (Islamic Republic Of)", id: "IR" },
          { name: "Iraq", id: "IQ" },
          { name: "Ireland", id: "IE" },
          { name: "Israel", id: "IL" },
          { name: "Italy", id: "IT" },
          { name: "Jamaica", id: "JM" },
          { name: "Japan", id: "JP" },
          { name: "Jordan", id: "JO" },
          { name: "Kazakhstan", id: "KZ" },
          { name: "Kenya", id: "KE" },
          { name: "Kiribati", id: "KI" },
          { name: "Korea, Democratic People's Republic Of", id: "KP" },
          { name: "Korea, Republic Of", id: "KR" },
          { name: "Kuwait", id: "KW" },
          { name: "Kyrgyzstan", id: "KG" },
          { name: "Lao People's Democratic Republic", id: "LA" },
          { name: "Latvia", id: "LV" },
          { name: "Lebanon", id: "LB" },
          { name: "Lesotho", id: "LS" },
          { name: "Liberia", id: "LR" },
          { name: "Libyan Arab Jamahiriya", id: "LY" },
          { name: "Liechtenstein", id: "LI" },
          { name: "Lithuania", id: "LT" },
          { name: "Luxembourg", id: "LU" },
          { name: "Macau", id: "MO" },
          { name: "Macedonia, The Former Yugoslav Republic Of", id: "MK" },
          { name: "Madagascar", id: "MG" },
          { name: "Malawi", id: "MW" },
          { name: "Malaysia", id: "MY" },
          { name: "Maldives", id: "MV" },
          { name: "Mali", id: "ML" },
          { name: "Malta", id: "MT" },
          { name: "Marshall Islands", id: "MH" },
          { name: "Martinique", id: "MQ" },
          { name: "Mauritania", id: "MR" },
          { name: "Mauritius", id: "MU" },
          { name: "Mayotte", id: "YT" },
          { name: "Mexico", id: "MX" },
          { name: "Micronesia, Federated States Of", id: "FM" },
          { name: "Moldova, Republic Of", id: "MD" },
          { name: "Monaco", id: "MC" },
          { name: "Mongolia", id: "MN" },
          { name: "Montserrat", id: "MS" },
          { name: "Morocco", id: "MA" },
          { name: "Mozambique", id: "MZ" },
          { name: "Myanmar", id: "MM" },
          { name: "Namibia", id: "NA" },
          { name: "Nauru", id: "NR" },
          { name: "Nepal", id: "NP" },
          { name: "Netherlands", id: "NL" },
          { name: "Netherlands Antilles", id: "AN" },
          { name: "New Caledonia", id: "NC" },
          { name: "New Zealand", id: "NZ" },
          { name: "Nicaragua", id: "NI" },
          { name: "Niger", id: "NE" },
          { name: "Nigeria", id: "NG" },
          { name: "Niue", id: "NU" },
          { name: "Norfolk Island", id: "NF" },
          { name: "Northern Mariana Islands", id: "MP" },
          { name: "Norway", id: "NO" },
          { name: "Oman", id: "OM" },
          { name: "Pakistan", id: "PK" },
          { name: "Palau", id: "PW" },
          { name: "Panama", id: "PA" },
          { name: "Papua New Guinea", id: "PG" },
          { name: "Paraguay", id: "PY" },
          { name: "Peru", id: "PE" },
          { name: "Philippines", id: "PH" },
          { name: "Pitcairn", id: "PN" },
          { name: "Poland", id: "PL" },
          { name: "Portugal", id: "PT" },
          { name: "Puerto Rico", id: "PR" },
          { name: "Qatar", id: "QA" },
          { name: "Reunion", id: "RE" },
          { name: "Romania", id: "RO" },
          { name: "Russian Federation", id: "RU" },
          { name: "Rwanda", id: "RW" },
          { name: "Saint Kitts And Nevis", id: "KN" },
          { name: "Saint Lucia", id: "LC" },
          { name: "Saint Vincent And The Grenadines", id: "VC" },
          { name: "Samoa", id: "WS" },
          { name: "San Marino", id: "SM" },
          { name: "Sao Tome And Principe", id: "ST" },
          { name: "Saudi Arabia", id: "SA" },
          { name: "Senegal", id: "SN" },
          { name: "Seychelles", id: "SC" },
          { name: "Sierra Leone", id: "SL" },
          { name: "Singapore", id: "SG" },
          { name: "Slovakia (Slovak Republic)", id: "SK" },
          { name: "Slovenia", id: "SI" },
          { name: "Solomon Islands", id: "SB" },
          { name: "Somalia", id: "SO" },
          { name: "South Africa", id: "ZA" },
          { name: "South Georgia And The South Sandwich Islands", id: "GS" },
          { name: "Spain", id: "ES" },
          { name: "Sri Lanka", id: "LK" },
          { name: "St. Helena", id: "SH" },
          { name: "St. Pierre And Miquelon", id: "PM" },
          { name: "Sudan", id: "SD" },
          { name: "Suriname", id: "SR" },
          { name: "Svalbard And Jan Mayen Islands", id: "SJ" },
          { name: "Swaziland", id: "SZ" },
          { name: "Sweden", id: "SE" },
          { name: "Switzerland", id: "CH" },
          { name: "Syrian Arab Republic", id: "SY" },
          { name: "Taiwan, Province Of China", id: "TW" },
          { name: "Tajikistan", id: "TJ" },
          { name: "Tanzania, United Republic Of", id: "TZ" },
          { name: "Thailand", id: "TH" },
          { name: "Togo", id: "TG" },
          { name: "Tokelau", id: "TK" },
          { name: "Tonga", id: "TO" },
          { name: "Trinidad And Tobago", id: "TT" },
          { name: "Tunisia", id: "TN" },
          { name: "Turkey", id: "TR" },
          { name: "Turkmenistan", id: "TM" },
          { name: "Turks And Caicos Islands", id: "TC" },
          { name: "Tuvalu", id: "TV" },
          { name: "Uganda", id: "UG" },
          { name: "Ukraine", id: "UA" },
          { name: "United Arab Emirates", id: "AE" },
          { name: "United Kingdom", id: "GB" },
          { name: "United States", id: "US" },
          { name: "United States Minor Outlying Islands", id: "UM" },
          { name: "Uruguay", id: "UY" },
          { name: "Uzbekistan", id: "UZ" },
          { name: "Vanuatu", id: "VU" },
          { name: "Venezuela", id: "VE" },
          { name: "Viet Nam", id: "VN" },
          { name: "Virgin Islands (British)", id: "VG" },
          { name: "Virgin Islands (U.S.)", id: "VI" },
          { name: "Wallis And Futuna Islands", id: "WF" },
          { name: "Western Sahara", id: "EH" },
          { name: "Yemen", id: "YE" },
          { name: "Yugoslavia", id: "YU" },
          { name: "Zambia", id: "ZM" },
          { name: "Zimbabwe", id: "ZW" },
        ],
        paymentConfirm: false, // 确认支付
        isTip: false,
        agree: false,
        showpaid: 0,
        fileList: [],
        currentBalance: "",
        paymentAmount: "",
        bank_info: {},
        selectedActive: this.$route.query.is_subscribe ? "5" : '2',
        params: {
          card_number: "",
          cvNumber: "",
          card_type: "",
          first_name: "",
          last_name: "",
          year: "",
          month: "",
          transaction_id: "",
          orderId: "",
          offline_certificate: "",
          email: "",
          country: "",
          state: "",
          city: "",
          street: "",
          postalCode: "",
        },
        bankInfo: {},
        single: "",
        batch: "",

        dialogImageUrl: "",
        dialogVisible: false,
        actionCode: "",
        accessToken: "",
        url475: "", // 475
        token475: "",
        userId: "",
        payWord: "", // 是否设置支付密码1：是，0未设置
        pass1: "",
        pass2: "",
        pass3: "",
        pass4: "",
        pass5: "",
        pass6: "",

        year: "",

        info: {},
        country: "",
        offlineShow: false,
        isJava: Boolean,
        isJavaScript: false,
        colorDeep: "",
        language: "",
        height: "",
        width: "",
        time: "",
        fileType: "",
        common_type: this.$route.query.model_type,

        clientSecret: "",
        order_no: "",
        loading: false,
        goods_info: {},
        subscribe_info: {},
        subLoading: false,
        dialogTip: false,
        defaultVal: 1,
        pay_type: {}
      };
    },

    computed: {
      yearList() {
        let yearList = [];
        let year = new Date().getFullYear();
        for (let index = 0; index <= new Date().getYear(); index++) {
          yearList.push(year + index);
        }
        return yearList;
      },
      monthList() {
        return month();
      },
    },

    created() {
      if (this.$route.query.order_no) {
        this.loading = true;
        let type = this.common_type;
        let order_no = this.$route.query.order_no;
        let package_type = this.$route.query.package_type;
        let model_id = this.$route.query.model_id;
        let hrefStripe = window.location.href.split("#")[0].split("?")[0];
        let arrayStripe = window.location.href.split("#")[0].split("?")[1].split("&");
        // console.log(111, arrayStripe);

        let objStripe;
        arrayStripe.forEach((element) => {
          objStripe = {
            ...objStripe,
            [element.split("=")[0]]: element.split("=")[1],
          };
        });
        switch (objStripe.redirect_status) {
          case "succeeded":
            this.$http.stripeSuccess({
              type,
              order_no,
              payment_intent: objStripe.payment_intent,
            }).then((res) => {
              if (res.status == 200) {
                let url;
                if (!model_id && !package_type) {
                  url = `${hrefStripe}#/PaySuccess?model_type=${type}`;
                } else if (!model_id) {
                  url = `${hrefStripe}#/PaySuccess?model_type=${type}&package_type=${package_type}`;
                } else if (!package_type) {
                  url = `${hrefStripe}#/PaySuccess?model_type=${type}&model_id=${model_id}`;
                } else {
                  url = `${hrefStripe}#/PaySuccess?model_type=${type}&model_id=${model_id}&package_type=${package_type}`;
                }
                window.location.href = url;
              } else {
                // this.goHome()
              }
            });
            // console.log(window.location.href);
            break;
          case "processing":
            this.$message.warning("Your payment is processing.");
            break;
          case "requires_payment_method":
            let url = `${hrefStripe}#/PayFailed?model_type=${type}`;
            window.location.href = url;
            break;
          default:
            window.location.href = `${hrefStripe}#/PayFailed?model_type=${type}&stripe_status=1`;
            // this.$message.warning('Something went wrong.')
            break;
        }
      }
    },
    mounted() {
      if (this.$route.query.is_subscribe) {
        if (this.$route.query.model_type) {
          this.getPayConfig();
          this.getUserInfo();
          this.getBlowes();
        } else {
          this.subscribe_info = JSON.parse(sessionStorage.getItem("subscribe_info")) || {}
          let subscribe_data = JSON.parse(sessionStorage.getItem("subscribe_data")) || {}
          this.subscription_id = subscribe_data.subscription_id
          this.payment_intent_id = subscribe_data.payment_intent_id
          this.hosted_invoice_url = subscribe_data.hosted_invoice_url
          if (this.subscription_id) this.checkPolling()
          if (!this.subscribe_info.amount) return this.$router.back()
        }
      } else {
        this.getPayConfig();
        this.getUserInfo();
        this.getBlowes();
      }
    },
    // beforeMount() {
    //   window.addEventListener(
    //     "message",
    //     (event) => {
    //       // console.log(event);
    //       // {MessageType: "profile.completed", Session Id: "0_57f063fd-659a-4779-b45b-9e456fdb7935", Status: true}
    //       if (
    //         event.origin === "https://centinelapistag.cardinalcommerce.com" ||
    //         event.origin === "https://centinelapi.cardinalcommerce.com"
    //       ) {
    //         let data = JSON.parse(event.data);
    //         // console.log("Merchant received a message:", data);
    //         if (data.Status == true) {
    //           if (this.params.cvNumber) {
    //             this.modelRecharge();
    //             this.params.cvNumber = "";
    //           }
    //         }
    //       }
    //       if (event.data !== undefined && event.data.Status) {
    //         // console.log("Songbird ran DF successfully");
    //       }
    //     },
    //     false
    //   );
    // },
    methods: {
      // 取消订单
      goBack() {
        window.history.go(-1);
        setInterval(() => {
          location.reload();
        }, 1000);
      },
      // 上传支付凭证
      uploadVoucher(params) {
        this.loading = true;
        var formData = new FormData();
        formData.append("file", params.file);
        this.fileType = formData.get("file").type;
        this.$http.uploadImage(formData).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            // console.log(res.data.toString());
            this.params.offline_certificate = res.data.toString();
            this.$message.success("Upload succeeded, display later!");
          }
        });
      },
      payConfirm() {
        if (
          (this.selectedActive == "3" || this.selectedActive == "4") &&
          this.showpaid == 0
        ) {
          this.showpaid = 1;
          this.paymentConfirm = true;
        } else {
          this.handleClickPayNow();
        }
      },
      payBack() {
        this.paymentConfirm = false;
      },
      tipBlock() {
        this.isTip = true;
      },
      tipNone() {
        this.isTip = false;
      },
      getBlowes() {
        this.isJava = navigator.javaEnabled(); // 启用Java
        this.isJavaScript = true; // 是否启用JavaScript
        this.language = navigator.language; // 浏览器语言
        this.width = document.documentElement.clientWidth; // 屏幕宽度
        this.height = document.documentElement.clientHeight; // 屏幕高度
        this.colorDeep = screen.colorDepth; // 颜色深度
        this.time = new Date(); // 时间
      },
      onload() {
        let transactionCodeInput = document.getElementsByClassName("wrap")[0];
        transactionCodeInput.onkeydown = function (e) {
          let target = e.srcElement || e.target;
          let keyCode = e.keyCode;
          let maxLength = parseInt(target.attributes["maxlength"].value, 10);
          let myLength = target.value.length;
          if (myLength >= maxLength) {
            let next = target;
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  break;
                } else {
                  next.focus();
                  break;
                }
              }
            }
          } else if (myLength === 0) {
            // Move to previous field if empty (user pressed backspace)
            let previous = target;
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  previous.focus();
                  break;
                } else {
                  break;
                }
              }
            }
          }
        };
      },
      getUserInfo() {
        this.$http.getUserInfo().then((res) => {
          if (res.status == 200) {
            this.userId = res.data.user_info.user_id;
            this.params.email = res.data.email;
            this.params.first_name = res.data.user_info.first_name;
            this.params.last_name = res.data.user_info.last_name;
            this.payWord = res.data.pay_password;
            // console.log(res.data.pay_password);
          }
        });
      },
      payMoney() {
        let data;
        if (this.selectedActive == 5) {
          return;
        } else {
          data = {
            money: this.paymentAmount,
            type: this.common_type,
            pay_type: this.selectedActive,
            [["2", "4", "5", "6", "8"].includes(this.$route.query.model_type) ? "id" : "order_id"]: this.$route.query.id,
            order_number: this.params.transaction_id,
            offline_certificate: this.params.offline_certificate,
            transaction_id: this.params.transaction_id,
            pay_price: this.paymentAmount,
          };
        }
        this.$http.payMoney(data).then((res) => {
          // this.orderId = res.data.payment_order_id;
          let package_type = this.$route.query.package_type;
          let model_id = this.$route.query.model_id;
          if (res.status == 200 && this.selectedActive != 2) {
            if (this.selectedActive != 3 && this.selectedActive != 4) {
              this.$router.push({
                path: "/PaySuccess",
                query: {
                  model_type: this.common_type,
                  [model_id ? "model_id" : ""]: model_id,
                  [package_type ? "package_type" : ""]: package_type,
                  // schedule_id: this.$route.query.schedule_id,
                  // cType: this.$route.query.cType,
                  // typeStatus: this.selectedActive,
                  // orderId: this.orderId
                },
              });
            } else {
              this.showpaid = 1;
              this.offlineShow = true;
            }
          } else {
            window.open(res.data.url, "_self");
            this.$setlocalStorage("PayId", res.data.order_no);
            // var ddcForm = document.querySelector("#ddc-form");
            // ddcForm.action =
            //   res.data.payerAuthSetupReply.deviceDataCollectionURL;
            // this.$refs.input.value = res.data.payerAuthSetupReply.accessToken;
            // if (ddcForm) {
            //   ddcForm.submit();
            // }
          }
        });
      },
      clearParams() {
        Object.keys(this.params).forEach((v) => {
          if (v === "is_batch") {
            this.params[v] = 0;
          } else {
            this.params.is_batch = "";
          }
        });
      },
      // currentTypeGoToUrl () {
      //   let type = this.common_type
      //   if (type == 5) {
      //     this.$router.push('/settings')
      //   } else if (type == 3) {
      //     this.$router.push({
      //       name: 'social.course-detail',
      //       query: { course_id: this.id }
      //     })
      //   } else if (type == 2) {
      //     this.$router.push(`/project-detail?id=${this.goToId}&type=1`)
      //   }
      // },
      handleClickPayNow() {
        if (this.selectedActive == 1) {
          if (this.payWord == 1) {
            this.$bvModal.show("payPassword");
          } else {
            this.$message.warning(
              "Please set the payment password before payment"
            );
            this.$router.push({
              path: "/settings?id=1",
              query: { setStatus: true },
            });
          }
        } else if (this.selectedActive == 2) {
          this.payMoney();
        } else if (this.selectedActive == 5) {
          let data = {
            type: this.common_type,
            pay_type: this.selectedActive,
            [["2", "4", "5", "6", "8"].includes(this.$route.query.model_type) ? "id" : "order_id"]: this.$route.query.id,
            pay_price: this.paymentAmount,
          };
          this.$http.payMoney(data).then((res) => {
            if (res.status == 200) {
              this.clientSecret = res.data.clientSecret;
              this.order_no = res.data.order_no;
              this.dialogVisible = true;
            } else if (res.status == 9988) {
              this.$message.warning(res.message);
            }
          });
        }
      },

      // 验证支付密码
      verifyPaymentPassword() {
        let word = `${this.pass1}${this.pass2}${this.pass3}${this.pass4}${this.pass5}${this.pass6}`;
        this.$http.verifyPaymentPassword({ pay_password: word }).then((res) => {
          if (res.status == 200) {
            this.$bvModal.hide("payPassword");
            this.payMoney();
          }
        });
      },
      // 公共支付
      // async modelRecharge() {
      //   let type = this.common_type;
      //   let ajaxType = {
      //     1: "modelRecharge",
      //     2: "eventRecharge",
      //     3: "courseRecharge",
      //     4: "meetingRecharge",
      //     7: "courseCertificateRecharge",
      //   };
      //   const data = {
      //     money: this.paymentAmount,
      //     user_id: this.userId,
      //     order_sn: this.orderId,
      //   };
      //   let res = await this.$http[ajaxType[type]](data);
      //   // console.log(res);
      //   this.transactionId = res.data.transaction_id;
      //   this.orderId = res.data.order_no;
      //   if (res.status == 200) {
      //     this.clearParams();
      //     this.currentTypeGoToUrl();
      //     this.$router.push({
      //       path: "/PaySuccess",
      //       query: {
      //         transactionId: this.transactionId,
      //         type: type,
      //         orderId: this.orderId,
      //       },
      //     });
      //   } else if (res.status == 475) {
      //     this.showBtn = 2;
      //     // console.log(res.data);
      //     this.url475 = res.data.url;
      //     this.token475 = res.data.token;
      //     let stepUpForm = document.querySelector("#step-up-form");
      //     stepUpForm.action = res.data.url;
      //     // console.log(this.$refs.int);
      //     this.$refs.int.value = res.data.token;
      //     if (stepUpForm) {
      //       stepUpForm.submit();
      //       this.publicPolling();
      //       this.getPayConfig();
      //     }
      //   } else {
      //     // this.transactionId = res.data.order_no;
      //     this.$router.push({
      //       path: "/PayFailed",
      //       query: {
      //         transactionId: this.transactionId,
      //         type: type,
      //         orderId: this.orderId,
      //       },
      //     });
      //   }
      // },
      // 支付回调
      // publicPolling() {
      //   let type = this.common_type;
      //   let ajaxType = {
      //     1: "publicPolling",
      //     2: "eventPolling",
      //     3: "coursePolling",
      //     4: "meetingPolling",
      //     7: "certificatePolling",
      //   };
      //   let setPolling = setTimeout(() => {
      //     this.$http[ajaxType[type]]({ order_id: this.orderId }).then((res) => {
      //       this.transactionId = res.data.transaction_id;
      //       if (res.data.status == "2") {
      //         clearTimeout(setPolling);
      //         this.$router.push({
      //           path: "/PaySuccess",
      //           query: {
      //             type: type,
      //             transactionId: this.transactionId,
      //             orderId: this.orderId,
      //           },
      //         });
      //       } else if (res.data.status == "7") {
      //         clearTimeout(setPolling);
      //         this.$router.push({
      //           path: "/PayFailed",
      //           query: {
      //             type: type,
      //             transactionId: this.transactionId,
      //             orderId: this.orderId,
      //           },
      //         });
      //       } else {
      //         this.publicPolling();
      //       }
      //     });
      //   }, 1000);
      // },
      // 获取用户余额与价格
      getPayConfig() {
        const data = {
          type: this.common_type,
          id: this.$route.query.id,
        };
        this.$http.getPayConfig(data).then((res) => {
          if (res.status == 200) {
            this.currentBalance = res.data.balance;
            this.paymentAmount = res.data.pay_price;
            this.goods_info = res.data.goods_info;
            this.single = res.data.pay_3;
            this.batch = res.data.pay_4;
            this.bank_info = res.data.bank_info || res.data;
            this.bankInfo = res.data.bank_info_4;
            this.pay_type = res.data.pay_type
            if (this.common_type == 9) {
              let goods_info = res.data.goods_info;
              this.subscription_id = goods_info.subscription_id
              this.payment_intent_id = goods_info.payment_intent_id
              this.hosted_invoice_url = goods_info.payment_link
              this.$set(this.subscribe_info, 'amount', res.data.pay_price)
              this.$set(this.subscribe_info, 'start_date', goods_info.start_date)
              this.$set(this.subscribe_info, 'end_date', goods_info.end_date)
              this.$set(this.subscribe_info, 'donation_amount', goods_info.donation_amount)
              this.$set(this.subscribe_info, 'is_end_date', goods_info.end_date ? 1 : 0)
              this.selectedActive = String(goods_info.payment_method)
            }
          }
        });
      },
      isPay() {
        let isPay = Number(this.currentBalance) >= Number(this.paymentAmount);
        if (!isPay) {
          this.$message.warning("Insufficient current account balance!");
        }
        return isPay;
      },

      // 订阅支付
      toSubscribePay() {
        if (this.subscription_id) {
          if (this.selectedActive == 2) {
            window.open(this.hosted_invoice_url, '_self')
          } else {
            window.open(this.hosted_invoice_url, '_blank')
            this.dialogTip = true
          }
          this.checkPolling()
        } else {
          this.createStripeSubscription()
        }
      },

      // 创建订阅【基金会-Stripe】
      createStripeSubscription() {
        let data = {
          donation_amount: this.subscribe_info.donation_amount,
          amount: this.subscribe_info.amount,
          donor_country_id: this.subscribe_info.donor_country_id,
          donor_address: this.subscribe_info.donor_address,
          donor_telephone: this.subscribe_info.donor_telephone,
          donor_company: this.subscribe_info.donor_company,
          donor_fax: this.subscribe_info.donor_fax,
          email: this.subscribe_info.email,
          name: this.subscribe_info.name,
          cycles: this.subscribe_info.cycles,
          is_end_date: this.subscribe_info.is_end_date,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        this.subLoading = true
        let ajaxType = { 2: "createPayPalSubscription", 5: "createStripeSubscription" }
        this.$http[ajaxType[this.selectedActive]](data).then((res) => {
          if (res.status == 200) {
            sessionStorage.setItem('subscribe_data', JSON.stringify(res.data))
            this.subscription_id = res.data.subscription_id
            this.payment_intent_id = res.data.payment_intent_id
            this.hosted_invoice_url = res.data.hosted_invoice_url
            if (this.selectedActive == 2) {
              this.$setlocalStorage("PayId", res.data.subscription_id);
              window.open(this.hosted_invoice_url, '_self')
            } else {
              window.open(this.hosted_invoice_url, '_blank')
              this.dialogTip = true
            }
            setTimeout(() => {
              this.subLoading = false
            }, 0);
          }
        });
        this.checkPolling()
      },

      // 查询订单是否支付
      checkOrderIsPaid() {
        let ajaxType = { 2: "checkOrderPayPalIsPaid", 5: "checkOrderIsPaid" }
        this.$http[ajaxType[this.selectedActive]]({
          subscription_id: this.subscription_id,
          payment_intent_id: this.payment_intent_id
        }).then((res) => {
          if (res.status == 200) {
            clearInterval(this.intervalId)
            this.$router.push({
              path: "/PaySuccess",
              query: {
                model_type: 9,
              },
            });
          }
        });
      },

      // 轮询
      checkPolling() {
        this.intervalId = setInterval(this.checkOrderIsPaid, 5000);
      },

      // 弹框查询支付状态
      async popupCheckPay() {
        let ajaxType = { 2: "checkOrderPayPalIsPaid", 5: "checkOrderIsPaid" }
        let res = await this.$http[ajaxType[this.selectedActive]]({
          subscription_id: this.subscription_id,
          payment_intent_id: this.payment_intent_id
        })
        if (res.status == 200) {
          clearInterval(this.intervalId)
          this.$router.push({
            path: "/PaySuccess",
            query: {
              model_type: 9,
            },
          });
        } else if (res.status == 4002) {
          // 已取消订阅
          clearInterval(this.intervalId)
          this.$message.warning('Subscription cancelled, please try again')
          this.$router.push('/MyOtherOrder?model_type=9')
        } else if (res.status == 4001) {
          // 未支付
          this.$message.warning('Payment not found, please make a new payment')
        }
        this.dialogTip = false
      }
    },

    beforeDestroy() {
      sessionStorage.removeItem('subscribe_info')
      sessionStorage.removeItem('subscribe_data')
      clearInterval(this.intervalId)
    },
  };
</script>
<style lang="scss" scoped>
  @media (min-width: 576px) {
    ::v-deep .el-dialog {
      width: 520px;
    }

    ::v-deep .custom-checkbox {
      margin-left: 1rem;
    }

    .payment {
      width: 100%;
      border: 1px solid rgba(80, 181, 255, 01);
      background: rgba(245, 251, 255, 1);
      border-radius: 5px;
      margin-bottom: 15px;
      margin-top: 15px;

      p {
        margin: 0;
        padding: 5px;
      }
    }

    .btns {
      text-align: center;

      button {
        width: 100px;
        height: 35px;
        border: 1px solid rgb(80, 181, 255);
        border-radius: 5px;
        margin: 5px;
      }

      .btn1 {
        color: rgb(80, 181, 255);
        background: rgba(245, 251, 255, 1);
      }

      .btn2 {
        color: rgba(245, 251, 255, 1);
        background: rgba(80, 181, 255, 1);
      }
    }

    .appendAsterisk::after {
      content: "*";
      color: red;
      display: inline;
      margin-left: 5px;
    }

    .tips {
      width: 630px;
      margin-left: 30px;
      color: #ffba68;
      background: #fffcf3;
      border: 1px solid #ffba68;
      text-align: center;
    }

    svg {
      position: relative;
      color: #ffba68;
    }

    .cvnTips {
      position: absolute;
      left: 130px;
      width: 50%;
      top: 420px;
      color: #ffba68;
      font-size: 12px;
      z-index: 999999;
      background: #fffcf3;
      padding: 5px;
      border: 1px solid #ffba68;
      border-radius: 5px;
    }

    .num {
      width: 630px;
      margin-left: 30px;
      background: #f8f8f8;

      .info {
        font-size: 14px;
        text-align: left;
        color: #666666;
      }
    }

    section {
      width: 90%;
      // margin: 0 auto;
      position: relative;

      .pay_password {
        width: 690px;
      }

      .option_back {
        position: absolute;
        display: flex;
        left: -110px;
        top: 0px;

        .to_my_training {
          margin: 10px 0;
          cursor: pointer;
          border-bottom: 1px solid #50b5ff;
          color: #50b5ff;
          line-height: 18px;
        }
      }
    }

    .form-text {
      font-size: 16px;
      color: #333;
      font-weight: 600;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
      height: 45px;

      ::v-deep .el-input__inner {
        height: 100%;
        border-radius: 10px;
      }
    }

    .pwd {
      width: 45px;
      height: 45px;
      margin: 10px;
      opacity: 1;
      border: 1px solid #f1f1f1;
      border-radius: 11px;
      text-align: center;
      vertical-align: middel;
    }

    .avatar-uploader {
      width: 90px;
      height: 90px;
      overflow: hidden;

      img {
        width: 90px;
        height: 90px;
      }
    }

    .goods_info_style {
      .goods_item {
        color: #333333;
        font-size: 16px;
      }
    }

    .totalPri {
      font-size: 14px;

      .font-weight-bold {
        font-size: 18px;
      }

      .fontUnit {
        font-size: 16px;
      }
    }
  }

  ::v-deep .loading_btn {
    border: none !important;
  }

  @media (max-width: 576px) {
    ::v-deep .el-dialog {
      width: 90%;
    }

    ::v-deep .custom-control {
      padding: 3px 0 0 1.5rem;
    }

    ::v-deep .custom-checkbox {
      margin-left: 0.5rem;
    }

    ::v-deep .custom-control-label {
      font-size: 12px;
      line-height: 20px;
    }

    ::v-deep .custom-control-label::before {
      width: 14px;
      height: 14px;
      left: -18px;
      top: 2px;
    }

    ::v-deep .custom-control-label::after {
      top: 1px;
      left: -19px;
    }

    .el-row {
      margin: 0 -20px !important;

      h4 {
        font-size: 14px;
      }
    }

    .payment {
      width: 100%;
      border: 1px solid rgba(80, 181, 255, 01);
      background: rgba(245, 251, 255, 1);
      border-radius: 5px;
      margin-bottom: 15px;
      margin-top: 15px;

      p {
        margin: 0;
        padding: 5px;
      }
    }

    .btns {
      text-align: center;

      button {
        width: 100px;
        height: 35px;
        border: 1px solid rgb(80, 181, 255);
        border-radius: 5px;
        margin: 5px;
      }

      .btn1 {
        color: rgb(80, 181, 255);
        background: rgba(245, 251, 255, 1);
      }

      .btn2 {
        color: rgba(245, 251, 255, 1);
        background: rgba(80, 181, 255, 1);
      }
    }

    .appendAsterisk::after {
      content: "*";
      color: red;
      display: inline;
      margin-left: 5px;
    }

    .tips {
      width: 90%;
      font-size: 12px;
      margin: 0 auto;
      color: #ffba68;
      background: #fffcf3;
      border: 1px solid #ffba68;
      text-align: center;
    }

    svg {
      position: relative;
      color: #ffba68;
    }

    .cvnTips {
      position: absolute;
      left: 130px;
      width: 50%;
      top: 420px;
      color: #ffba68;
      font-size: 12px;
      z-index: 999999;
      background: #fffcf3;
      padding: 5px;
      border: 1px solid #ffba68;
      border-radius: 5px;
    }

    .num {
      width: 90%;
      margin: 0 auto;
      background: #f8f8f8;

      h5 {
        font-size: 14px;
      }

      .info {
        font-size: 12px;
        text-align: left;
        color: #666666;
        margin-bottom: 8px;
      }
    }

    section {
      width: 90%;
      margin: 0 auto;
      position: relative;

      .pay_password {
        width: 100%;

        ::v-deep .col-form-label {
          font-size: 12px;
        }

        .form-control {
          font-size: 12px;
        }
      }

      .option_back {
        position: absolute;
        display: flex;
        left: -110px;
        top: 0px;

        .to_my_training {
          margin: 10px 0;
          cursor: pointer;
          border-bottom: 1px solid #50b5ff;
          color: #50b5ff;
          line-height: 18px;
        }
      }
    }

    .form-text {
      font-size: 16px;
      color: #333;
      font-weight: 600;

    }

    .activeType {
      border: 1px solid rgb(80, 181, 255);
      display: flex;
      align-items: center;
      background: #f5fbff;
      padding: 6px 25px;
      margin: 5px 0;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
      height: 45px;

      ::v-deep .el-input__inner {
        height: 100%;
        border-radius: 10px;
      }
    }

    .pwd {
      width: 45px;
      height: 45px;
      margin: 10px;
      opacity: 1;
      border: 1px solid #f1f1f1;
      border-radius: 11px;
      text-align: center;
      vertical-align: middel;
    }

    .avatar-uploader {
      width: 90px;
      height: 90px;
      overflow: hidden;

      img {
        width: 90px;
        height: 90px;
      }
    }

    .goods_info_style {
      .goods_item {
        color: #333333;
        font-size: 12px;
      }
    }

    .totalPri {
      font-size: 10px;

      .font-weight-bold {
        font-size: 13px;
      }

      .fontUnit {
        font-size: 12px;
      }
    }

    ::v-deep .payBtn {
      font-size: 12px !important;
    }

    button {
      font-size: 12px !important;
      padding: 5px 10px;
      height: 30px;
    }
  }
</style>