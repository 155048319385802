const year = function () {
  let yearList = [];
  for (let index = 0; index <= new Date().getYear(); index++) {
    yearList.push(1900 + index)
  }
  return yearList
}

const month = function () {
  let monthList = [];
  for (let index = 1; index <= 12; index++) {
    if(index<=9){
      monthList.push(`0${index}`)
    }else {
      monthList.push(index)
    }
  }
  return monthList;
}

const days = function (year, month) {
  
  let daysList = [];
  switch (month) {
    case '01':
    case '03':
    case '05':
    case '07':
    case '08':
    case '10':
    case '12':
      return dayNum(31)
    case '04':
    case '06':
    case '09':
    case '11':
      return dayNum(30)
    case '02':
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
        ? dayNum(29)
        : dayNum(28)
    default:
      return 0
  }
  function dayNum(num) {
    for (let index = 1; index <= num; index++) {
      if(index<=9){
        daysList.push(`0${index}`)
      }else{
        daysList.push(index)
      }
    }
    return daysList;
  }
}

export {
  year,
  month,
  days
}
